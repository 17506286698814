@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400&family=Varela+Round&display=swap");

body {
  font-family: "Varela Round", sans-serif;
}

@media (max-height: 800px) {
  footer {
    position: static;
  }
  header {
    padding-top: 40px;
  }
}
@media (max-height: 280px) {
  .logo-footer {
    width: 100%;
  }
}
@media (max-height: 375px) {
  .img-footer2 {
    width: 100%;
    text-align: center;
  }
}

.footer-distributed {
  background-color: rgb(0, 149, 218);
  box-sizing: border-box;
  width: 100%;
  /* height: 500px; */
  text-align: left;
  font: bold 16px sans-serif;
  padding: 50px 50px 200px 50px;
  /* margin-top: 80px; */
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 30%;
}

.footer-distributed h3 {
  color: #ffffff;
  /* font: normal 36px 'Cookie', cursive; */
  margin: 0;
}

/* The company logo */

.footer-distributed .footer-left img {
  width: 30%;
}

.footer-distributed h3 span {
  color: #ffffff;
  font-family: "Varela Round", sans-serif;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
  font-family: "Varela Round", sans-serif;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.footer-distributed .footer-company-name {
  color: #ffffff;
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
  font-family: "Varela Round", sans-serif;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
  font-family: "Varela Round", sans-serif;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Varela Round", sans-serif;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 30%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  font-family: "Varela Round", sans-serif;
  text-align: justify;
}

.footer-company-about {
  font-size: 16px !important;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Varela Round", sans-serif;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: none;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
  font-family: "Varela Round", sans-serif;
}
.member-hilpi {
  margin-top: 20px;
  color: white;
  font-family: "Varela Round", sans-serif;
}

/* Here is the code for Responsive Footer */
/* You can remove below code if you don't want Footer to be responsive */

.logo-footer {
  /* filter: drop-shadow(5px 5px 10px #ffffff); */
  width: 50% !important;
}

.icon-sosmed {
  font-size: 23px;
}

@media (max-width: 880px) {
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}
@media only screen and (max-width: 912px) {
  .footer-company-about {
    margin-left: 50px !important;
  }
  .img-hilpi {
    width: 70%;
  }
}
@media only screen and (max-width: 412px) {
  .footer-company-about {
    margin-left: 0 !important;
  }
  .logo-footer {
    width: 70% !important;
  }
  .img-hilpi {
    width: 50% !important;
  }
}
@media only screen and (max-width: 768px) {
  .footer-company-about {
    margin-left: 0 !important;
  }
  .logo-footer {
    width: 50% !important;
  }
  .img-hilpi {
    width: 50% !important;
  }
}
