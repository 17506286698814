* {
  margin: 0;
  padding: 0;
}
.box-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: rgb(0, 149, 218);
  border-radius: 50% / 0 0 100px 100px;
  column-count: 2;
}
.deks-landing {
  font-size: 25px;
  width: 80%;
  margin-top: 20px;
}
.img-landing {
  width: 44%;
}
.content-desk {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 150px;
  padding-bottom: 180px;
  padding-top: 180px;
}
.text-button {
  text-decoration: none;
  color: white;
}
.btn-landing {
  background: orangered;
  padding: 20px;
  font-weight: 700;
  color: white;
  border: none;
  font-family: "Varela Round", sans-serif;
  font-size: 18px;
  margin-top: 40px;
  border-radius: 10px;
  margin-bottom: 50px;
  transition: all 0.5s;
  cursor: pointer;
}
.btn-landing:hover {
  border-radius: 50px;
  transition: all 0.5s;
  cursor: pointer;
}

.parent-detail {
  display: flex;
  justify-content: center;
}
.box-detail {
  position: relative;
  /* top: 64%; */
  margin-top: -12pc;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 60%;
  height: 250px;
  background: white;
  border-radius: 20px;
  border-bottom: 10px solid rgb(0, 149, 218);
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.desk-icon {
  margin-left: 10px;
}
.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  padding: 250px;
  padding-top: 0;
  padding-bottom: 0;
}
.welcome-text {
  color: rgb(0, 149, 218);
}
.desk-welcome {
  width: 60%;
  margin-top: 20px;
  font-size: 20px;
  line-height: 30px;
  color: rgb(82, 82, 82);
}
.img-landing2 {
  width: 35%;
}
.box-pengajar {
  margin-top: 200px;
  text-align: center;
  padding: 250px;
  padding-top: 20px;
  padding-bottom: 0;
  background: rgba(231, 247, 255, 0.644);
}
.desk-pengajar {
  font-size: 20px;
  margin-top: 20px;
  color: rgb(82, 82, 82);
}
.title-pengajar {
  color: rgb(0, 149, 218);
}

/* CARD */
.card-pengajar {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.main-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}
.img-card {
  width: 20rem;
  border-radius: 15px 15px 0 0;
}
/* .header-card {
  background: red;
  width: 100%;
  height: 30px;
} */
.text-card {
  font-size: 20px;
  margin-top: 20px;
  color: rgb(82, 82, 82);
}
.btn-card {
  background: orangered;
  padding: 14px;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  font-family: "Varela Round", sans-serif;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.2s;
}
.btn-card:hover {
  width: 80%;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.2s;
}
.chatus-card {
  text-decoration: none;
  color: white;
}
.prog-unggul {
  color: rgb(0, 149, 218);
}

/* SEMUA BIMBEL */
.box-listbimbel {
  column-count: 2;
}
.semua-bimbel {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.list-bimbel {
  list-style: none;
  margin-left: 30px;
  font-size: 18px;
  line-height: 35px;
  color: rgb(82, 82, 82);
}
.title-bimbel {
  text-align: center;
  color: rgb(0, 149, 218);
  /* margin-right: 60px; */
  text-decoration: underline;
}
.icon-listbimbel {
  color: rgb(0, 149, 218);
}

/* BIMBEL SEKOLAH */
.bimbel-sekolah {
  margin-top: 200px;
  text-align: center;
  color: rgb(0, 149, 218);
}
.card-bimbelsekolah {
  display: flex;
  justify-content: center;
}
.main-bimskol {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.5s;
  background: rgb(0, 149, 218);
  color: white;
}
.main-bimskol:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  transition: all 0.5s;
  background: white;
  color: rgb(0, 149, 218);
}
.img-bimbelsekolah {
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
}
.prog-unggul {
  color: rgb(0, 149, 218);
}
.text-bimskol {
  font-size: 20px;
  margin-top: 10px;
  /* color: rgb(255, 255, 255); */
}
.desk-bimskol {
  margin-bottom: 30px;
  color: rgb(82, 82, 82);
  font-size: 18px;
  margin-top: 20px;
}

/* BIMBEL KHUSUS */
.bimbel-khusus {
  margin-top: 200px;
  text-align: center;
  color: rgb(0, 149, 218);
}
.card-bimbelkhusus {
  display: flex;
  justify-content: center;
}
.main-bimkhus {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.5s;
  background: rgb(255, 255, 255);
  color: rgb(0, 149, 218);
}
.main-bimkhus:hover {
  cursor: pointer;
  transition: all 0.5s;
  background: rgb(0, 149, 218);
  color: rgb(255, 255, 255);
}
.img-bimbelkhusus {
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
}
.prog-unggul {
  color: rgb(0, 149, 218);
}
.text-bimkhus {
  font-size: 20px;
  margin-top: 10px;
  /* color: rgb(255, 255, 255); */
}
.desk-bimkhus {
  margin-bottom: 30px;
  color: rgb(82, 82, 82);
  font-size: 18px;
  margin-top: 20px;
}

/* KEUNGGULAN */
.keunggulan-edu {
  text-align: center;
  margin-top: 200px;
  color: rgb(0, 149, 218);
}
.card-unggul {
  display: flex;
  justify-content: center;
}
.main-unggul {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 20rem;
  border-radius: 15px 15px 15px 15px;
  padding: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.5s;
  background: rgb(255, 255, 255);
  color: rgb(82, 82, 82);
}
.main-unggul:hover {
  /* cursor: pointer;
    transition: all 0.5s;
    background: rgb(0, 149, 218);
    color: rgb(255, 255, 255); */
}
.img-unggul {
  width: 10rem;
  border-radius: 100%;
}
.prog-unggul {
  color: rgb(0, 149, 218);
}
.text-unggul {
  font-size: 20px;
  margin-top: 10px;
  /* color: rgb(255, 255, 255); */
  text-align: center;
  color: rgb(0, 149, 218);
  border-bottom: 1px solid rgb(123, 213, 255);
  padding-bottom: 10px;
}
.desk-keunggulan {
  text-align: justify;
  margin-top: 10px;
}
.parent-unggul {
  margin-top: 30px;
}
.keunggulan-edu {
  background: rgba(231, 247, 255, 0.644);
}
.title-keunggulan {
  padding-top: 20px;
}

/* TESTIMONI */
.box-testimoni {
  text-align: center;
  margin-top: 200px;
}
.title-testimoni {
  color: rgb(0, 149, 218);
}
.slider-testi {
  margin-top: 30px;
  padding: 100px;
  padding-top: 0;
  padding-bottom: 0;
}

/* FASILITAS */
.li-fasilitas {
  list-style: none;
  line-height: 28px;
  color: rgb(82, 82, 82);
  font-size: 18px;
}
.icon-fasilitas {
  color: rgb(0, 149, 218);
}
.fasilitas-program {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.title-fasilitas {
  margin-right: 50px;
  color: rgb(0, 149, 218);
  text-decoration: underline;
}

/* PROFESIONAL TEACHER */
.pro-teacher {
  margin-top: 200px;
  text-align: center;
}

/* SUCCESS STORY */
.title-story {
  color: rgb(0, 149, 218);
}
.success-story {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.li-story {
  list-style: none;
  color: rgb(82, 82, 82);
  line-height: 25px;
  font-size: 16px;
}
.list-story {
  column-count: 3;
}
.title-story {
  margin-left: 50px;
  text-decoration: underline;
}
.desk-story {
  margin-left: 50px;
  color: rgb(82, 82, 82);
}
.icon-story {
  color: rgb(0, 149, 218);
}

/* ALUMNI */
.box-alumni {
  margin-top: 200px;
  text-align: center;
}
.title-alumni {
  color: rgb(0, 149, 218);
}
.desk-alumni {
  color: rgb(82, 82, 82);
  font-size: 18px;
  margin-top: 20px;
}
.group-img {
  margin-top: 30px;
}
.alumni-img {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 30px;
}

/* END QUOT */
.end-qout {
  margin-top: 200px;
  /* text-align: center; */
  background: rgba(231, 247, 255, 0.644);
  padding: 150px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.title-quot {
  color: rgb(0, 149, 218);
  text-align: justify;
}
.desk-quot {
  color: rgb(82, 82, 82);
  font-size: 18px;
  text-align: justify;
}

/* WA FITUR */
.box-wa {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: rgb(0, 149, 218);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 99;
}
.wa {
  color: white;
  text-decoration: none;
  font-family: 'Varela Round', sans-serif;
  font-weight: 700;
  font-size: 15px;
}
.btn-wa {
  background: rgb(15, 168, 15);
  border: none;
  padding: 20px;
  border-radius: 50px;
  border: 1px solid white;
  cursor: pointer;
}
.call {
  color: white;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Varela Round', sans-serif;
  font-weight: 700;
}
.btn-call {
  background: orangered;
  border: none;
  padding: 20px;
  margin-left: 20px;
  border-radius: 50px;
  border: 1px solid white;
  cursor: pointer;
}

/* END PHONE */
.btn-viawa {
  background: rgb(15, 168, 15);
  color: white;
  border: none;
  padding: 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.btn-viawa:hover {
  background: rgb(8, 141, 8);
}
.icon-viawa {
  font-size: 20px;
}
.a-viawa {
  text-decoration: none;
  color: white;
  font-family: 'Varela Round', sans-serif;
}
.btn-viahp {
  background: orangered;
  color: white;
  border: 2px solid transparent;
  padding: 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.btn-viahp:hover {
  background: rgb(180, 48, 0);
}
.icon-viahp {
  font-size: 20px;
}
.a-viahp {
  text-decoration: none;
  color: white;
  font-family: 'Varela Round', sans-serif;
}
.all-btnend {
  margin-top: 20px;
  margin-bottom: 10px;
}
.parent-logoedu {
  display: grid;
  justify-content: center;
}
.img-logoedu {
  width: 40%;
  margin-bottom: 80px;
}

/* RESPONSIVE */

/* SURFACE 7 */
@media only screen and (max-width: 912px) {
  .content-desk {
    display: grid;
    padding-top: 50px;
    padding: 100px;
  }
  .box-detail {
    top: 98%;
    width: 80%;
  }
  .icon {
    margin-left: 25px;
  }
  .btn-landing {
    width: 100%;
    background: orangered;
  }
  .box-landing {
    border-radius: 50% / 0 0 100px 100px;
    width: 100%;
  }
  .welcome {
    flex-direction: column-reverse;
    padding: 50px;
  }
  .desk-welcome {
    width: 100%;
    text-align: justify;
  }
  .img-landing {
    margin-top: 20px;
    width: 100%;
  }
  .img-landing2 {
    width: 100%;
    width: 50%;
  }
  .univ {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .box-progedu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .semua-bimbel {
    display: grid;
  }
  .box-listbimbel {
    column-count: 1;
  }
  .card-pengajar {
    flex-wrap: wrap;
  }
  .prog-unggul {
    width: 100%;
  }
  .card-bimbelsekolah {
    flex-wrap: wrap;
  }
  .card-bimbelkhusus {
    flex-wrap: wrap;
  }
  .img-univ {
    width: 100%;
  }
  .card-unggul {
    display: flex;
    flex-wrap: wrap;
  }
  .desk-pengajar {
    width: 100%;
  }
  .box-pengajar {
    padding: 80px;
  }
  .box-wa {
    width: 100%;
  }
  .alumni-img {
    width: 100%;
  }
  .inti-img {
    width: 100%;
  }
  .fasilitas-program {
    display: grid;
    padding: 50px;
  }
  .title-fasilitas {
    text-align: center;
    margin-right: 0;
  }
  .li-fasilitas {
    line-height: 35px;
    text-align: justify;
  }
  .success-story {
    display: flex;
    flex-direction: column-reverse;
  }
  .list-story {
    column-count: 2;
    padding: 50px;
    padding-top: 20px;
  }
  .title-story {
    margin-left: 0;
    text-align: center;
  }
  .desk-story {
    text-align: center;
  }
  .box-alumni {
    padding: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-testi {
    padding: 20px;
  }
}

/* NEST HUB */
@media only screen and (max-width: 1024px) {
  .content-desk {
    display: grid;
    padding-top: 50px;
    padding: 100px;
  }
  .box-detail {
    top: 160%;
    width: 80%;
  }
  .icon {
    margin-left: 25px;
  }
  .btn-landing {
    width: 100%;
    background: orangered;
  }
  .box-landing {
    border-radius: 50% / 0 0 100px 100px;
    width: 100%;
  }
  .welcome {
    flex-direction: column-reverse;
    padding: 50px;
  }
  .desk-welcome {
    width: 100%;
    text-align: justify;
  }
  .img-landing {
    margin-top: 20px;
    width: 100%;
  }
  .img-landing2 {
    width: 100%;
    width: 50%;
  }
  .univ {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .box-progedu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .semua-bimbel {
    display: grid;
  }
  .box-listbimbel {
    column-count: 1;
  }
  .card-pengajar {
    flex-wrap: wrap;
  }
  .prog-unggul {
    width: 100%;
  }
  .card-bimbelsekolah {
    flex-wrap: wrap;
  }
  .card-bimbelkhusus {
    flex-wrap: wrap;
  }
  .img-univ {
    width: 100%;
  }
  .card-unggul {
    display: flex;
    flex-wrap: wrap;
  }
  .desk-pengajar {
    width: 100%;
  }
  .box-pengajar {
    padding: 80px;
  }
  .box-wa {
    width: 100%;
  }
  .alumni-img {
    width: 100%;
  }
  .inti-img {
    width: 100%;
  }
  .fasilitas-program {
    display: grid;
    padding: 50px;
  }
  .title-fasilitas {
    text-align: center;
    margin-right: 0;
  }
  .li-fasilitas {
    display: flex;
    justify-content: baseline;
    line-height: 35px;
    text-align: justify;
  }
  .success-story {
    display: flex;
    flex-direction: column-reverse;
  }
  .list-story {
    column-count: 2;
    padding: 50px;
    padding-top: 20px;
  }
  .title-story {
    margin-left: 0;
    text-align: center;
  }
  .desk-story {
    text-align: center;
  }
  .box-alumni {
    padding: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-testi {
    padding: 20px;
  }
}

/* NEST HUB MAX*/
@media only screen and (max-width: 1280px) {
  .content-desk {
    display: grid;
    padding-top: 50px;
    padding: 100px;
  }
  .box-detail {
    top: 130%;
    width: 80%;
  }
  .icon {
    margin-left: 25px;
  }
  .btn-landing {
    width: 100%;
    background: orangered;
  }
  .box-landing {
    border-radius: 50% / 0 0 100px 100px;
    width: 100%;
  }
  .welcome {
    flex-direction: column-reverse;
    padding: 50px;
  }
  .desk-welcome {
    width: 100%;
    text-align: justify;
  }
  .img-landing {
    margin-top: 20px;
    width: 100%;
  }
  .img-landing2 {
    width: 100%;
    width: 50%;
  }
  .univ {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .box-progedu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .semua-bimbel {
    display: grid;
  }
  .box-listbimbel {
    column-count: 1;
  }
  .card-pengajar {
    flex-wrap: wrap;
  }
  .prog-unggul {
    width: 100%;
  }
  .card-bimbelsekolah {
    flex-wrap: wrap;
  }
  .card-bimbelkhusus {
    flex-wrap: wrap;
  }
  .img-univ {
    width: 100%;
  }
  .card-unggul {
    display: flex;
    flex-wrap: wrap;
  }
  .desk-pengajar {
    width: 100%;
  }
  .box-pengajar {
    padding: 80px;
  }
  .box-wa {
    width: 100%;
  }
  .alumni-img {
    width: 100%;
  }
  .inti-img {
    width: 100%;
  }
  .fasilitas-program {
    display: grid;
    padding: 50px;
  }
  .title-fasilitas {
    text-align: center;
    margin-right: 0;
  }
  .li-fasilitas {
    line-height: 35px;
    text-align: justify;
  }
  .success-story {
    display: flex;
    flex-direction: column-reverse;
  }
  .list-story {
    column-count: 2;
    padding: 50px;
    padding-top: 20px;
  }
  .title-story {
    margin-left: 0;
    text-align: center;
  }
  .desk-story {
    text-align: center;
  }
  .box-alumni {
    padding: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-testi {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .content-desk {
    display: grid;
    padding-top: 50px;
    padding: 100px;
  }
  .box-detail {
    top: 80%;
    width: 80%;
  }
  .icon {
    margin-left: 25px;
  }
  .btn-landing {
    width: 100%;
    background: orangered;
  }
  .box-landing {
    border-radius: 50% / 0 0 100px 100px;
    width: 100%;
  }
  .welcome {
    flex-direction: column-reverse;
    padding: 50px;
  }
  .desk-welcome {
    width: 100%;
    text-align: justify;
  }
  .img-landing {
    margin-top: 20px;
    width: 100%;
  }
  .img-landing2 {
    width: 100%;
    width: 50%;
  }
  .univ {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .box-progedu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .semua-bimbel {
    display: grid;
  }
  .box-listbimbel {
    column-count: 1;
  }
  .card-pengajar {
    flex-wrap: wrap;
  }
  .prog-unggul {
    width: 100%;
  }
  .card-bimbelsekolah {
    flex-wrap: wrap;
  }
  .card-bimbelkhusus {
    flex-wrap: wrap;
  }
  .img-univ {
    width: 100%;
  }
  .card-unggul {
    display: flex;
    flex-wrap: wrap;
  }
  .desk-pengajar {
    width: 100%;
  }
  .box-pengajar {
    padding: 80px;
  }
  .box-wa {
    width: 100%;
  }
  .alumni-img {
    width: 100%;
  }
  .inti-img {
    width: 100%;
  }
  .fasilitas-program {
    display: grid;
    padding: 50px;
  }
  .title-fasilitas {
    text-align: center;
    margin-right: 0;
  }
  .li-fasilitas {
    display: flex;
    align-items: baseline;
    line-height: 35px;
    text-align: justify;
  }
  .success-story {
    display: flex;
    flex-direction: column-reverse;
  }
  .list-story {
    column-count: 2;
    padding: 50px;
    padding-top: 20px;
  }
  .title-story {
    margin-left: 0;
    text-align: center;
  }
  .desk-story {
    text-align: center;
    margin-bottom: 15px;
  }
  .box-alumni {
    padding: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-testi {
    padding: 20px;
  }
  .btn-viahp {
    margin-left: 10px;
    /* margin-top: 10px; */
  }
  .title-quot {
    text-align: left;
  }
  .all-btnend {
    margin-top: 10px;
  }
  .end-qout {
    padding: 100px;
  }
  .desk-bimskol {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .title-bimbelsekolah {
    padding: 10px;
  }
  .icon-fasilitas {
    margin-right: 10px;
  }
  .li-fasilitas {
    margin-top: 15px;
  }
  .title-bimbel {
    margin-bottom: 15px;
  }
  .parent-logoedu {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
}

/* IPAD AIR */
@media only screen and (max-width: 820px) {
  .content-desk {
    display: grid;
    padding-top: 50px;
    padding: 100px;
  }
  .box-detail {
    top: 75%;
    width: 80%;
  }
  .icon {
    margin-left: 25px;
  }
  .btn-landing {
    width: 100%;
    background: orangered;
  }
  .box-landing {
    border-radius: 50% / 0 0 100px 100px;
    width: 100%;
  }
  .welcome {
    flex-direction: column-reverse;
    padding: 50px;
  }
  .desk-welcome {
    width: 100%;
    text-align: justify;
  }
  .img-landing {
    margin-top: 20px;
    width: 100%;
  }
  .img-landing2 {
    width: 100%;
    width: 50%;
  }
  .univ {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .box-progedu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .semua-bimbel {
    display: grid;
  }
  .box-listbimbel {
    column-count: 1;
  }
  .card-pengajar {
    flex-wrap: wrap;
  }
  .prog-unggul {
    width: 100%;
  }
  .card-bimbelsekolah {
    flex-wrap: wrap;
  }
  .card-bimbelkhusus {
    flex-wrap: wrap;
  }
  .img-univ {
    width: 100%;
  }
  .card-unggul {
    display: flex;
    flex-wrap: wrap;
  }
  .desk-pengajar {
    width: 100%;
  }
  .box-pengajar {
    padding: 80px;
  }
  .box-wa {
    width: 100%;
  }
  .alumni-img {
    width: 100%;
  }
  .inti-img {
    width: 100%;
  }
  .fasilitas-program {
    display: grid;
    padding: 50px;
  }
  .title-fasilitas {
    text-align: center;
    margin-right: 0;
  }
  .li-fasilitas {
    line-height: 35px;
    text-align: justify;
  }
  .success-story {
    display: flex;
    flex-direction: column-reverse;
  }
  .list-story {
    column-count: 2;
    padding: 50px;
    padding-top: 20px;
  }
  .title-story {
    margin-left: 0;
    text-align: center;
  }
  .desk-story {
    text-align: center;
  }
  .box-alumni {
    padding: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .slider-testi {
    padding: 20px;
  }
  .btn-viahp {
    margin-left: 10px;
    /* margin-top: 10px; */
  }
  .title-quot {
    text-align: left;
  }
  .all-btnend {
    margin-top: 10px;
  }
  .end-qout {
    padding: 100px;
  }
}

@media only screen and (max-width: 550px) {
  .content-desk {
    padding: 30px;
  }
  .title-utama {
    font-size: 30px;
  }
  .deks-landing {
    width: 100%;
    text-align: justify;
    font-size: 18px;
  }
  .img-landing {
    margin-top: 0;
  }
  .box-detail {
    /* top: 70%; */
    margin-top: -3pc;
    display: grid;
  }
  .icon {
    display: grid;
    place-items: center;
    margin-left: 0;
  }
  .icon-detail {
    display: grid;
    place-items: center;
    margin-top: 10px;
    width: 40%;
  }
  .desk-icon {
    text-align: center;
  }
  .box-detail {
    height: auto;
  }
  .welcome {
    padding-top: 10px;
  }
  .title-bimbel {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title-bimbelsekolah {
    padding: 20px;
  }
  .title-bimbel {
    padding: 20px;
    padding-bottom: 0;
  }
  .box-pengajar {
    padding: 20px;
  }
  .desk-pengajar {
    text-align: justify;
  }
  .img-landing2 {
    width: 70%;
  }
  .img-teach {
    width: 100%;
  }
  .desk-story {
    margin-left: 0;
  }
  .list-story {
    column-count: 1;
  }
  .li-fasilitas {
    margin-top: 20px;
  }
  .desk-quot {
    font-size: 15px;
    text-align: justify;
  }
  .btn-viahp {
    margin-left: 10px;
    /* margin-top: 10px; */
  }
  .title-quot {
    text-align: left;
    font-size: 24px;
  }
  .all-btnend {
    margin-top: 10px;
  }
  .end-qout {
    padding: 40px;
  }
  .btn-viahp {
    margin-left: 0;
    padding: 12px;
    padding-right: 26px;
    margin-top: 10px;
  }
  .btn-viawa {
    padding: 12px;
  }
  .list-bimbel {
    display: flex;
    align-items: baseline;
  }
  .icon-listbimbel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-logoedu {
    width: 70%;
  }
  .img-landing2 {
    width: 100%;
  }
  .parent-logoedu {
    margin-top: 80px;
  }
  .li-story {
    display: flex;
  }
  .icon-story {
    display: flex;
    justify-content: baseline;
  }
}

/* IPHONE SE */
@media only screen and (max-width: 375px) {
  .content-desk {
    padding: 30px;
  }
  .title-utama {
    font-size: 30px;
  }
  .deks-landing {
    width: 100%;
    text-align: justify;
    font-size: 18px;
  }
  .img-landing {
    margin-top: 0;
  }
  .box-detail {
    top: 90%;
    display: grid;
  }
  .icon {
    display: grid;
    place-items: center;
    margin-left: 0;
  }
  .icon-detail {
    display: grid;
    place-items: center;
    margin-top: 10px;
    width: 40%;
  }
  .desk-icon {
    text-align: center;
  }
  .box-detail {
    height: auto;
  }
  .welcome {
    padding-top: 300px;
  }
  .title-bimbel {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title-bimbelsekolah {
    padding: 20px;
  }
  .title-bimbel {
    padding: 20px;
    padding-bottom: 0;
  }
  .box-pengajar {
    padding: 10px;
  }
  .desk-pengajar {
    text-align: justify;
  }
  .img-landing2 {
    width: 70%;
  }
  .img-teach {
    width: 100%;
  }
  .desk-story {
    margin-left: 0;
  }
  .list-story {
    column-count: 1;
  }
  .li-fasilitas {
    margin-top: 20px;
  }
  .desk-quot {
    font-size: 15px;
    text-align: justify;
  }
  .end-qout {
    padding: 20px;
  }
}

/* SAMSUNG S8+ */
@media only screen and (max-width: 360px) {
  .content-desk {
    padding: 30px;
  }
  .title-utama {
    font-size: 30px;
  }
  .deks-landing {
    width: 100%;
    text-align: justify;
    font-size: 18px;
  }
  .img-landing {
    margin-top: 0;
  }
  .box-detail {
    top: 85%;
    display: grid;
  }
  .icon {
    display: grid;
    place-items: center;
    margin-left: 0;
  }
  .icon-detail {
    display: grid;
    place-items: center;
    margin-top: 10px;
    width: 40%;
  }
  .desk-icon {
    text-align: center;
  }
  .box-detail {
    height: auto;
  }
  .welcome {
    padding-top: 300px;
  }
  .title-bimbel {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title-bimbelsekolah {
    padding: 20px;
  }
  .title-bimbel {
    padding: 20px;
    padding-bottom: 0;
  }
  .box-pengajar {
    padding: 10px;
  }
  .desk-pengajar {
    text-align: justify;
  }
  .img-landing2 {
    width: 70%;
  }
  .img-teach {
    width: 100%;
  }
  .desk-story {
    margin-left: 0;
  }
  .list-story {
    column-count: 1;
  }
  .li-fasilitas {
    margin-top: 20px;
  }
  .desk-quot {
    font-size: 15px;
    text-align: justify;
  }
  .end-qout {
    padding: 20px;
  }
}

/* SAMSUNG S20 */
@media only screen and (max-width: 360px) {
  .content-desk {
    padding: 30px;
  }
  .title-utama {
    font-size: 30px;
  }
  .deks-landing {
    width: 100%;
    text-align: justify;
    font-size: 18px;
  }
  .img-landing {
    margin-top: 0;
  }
  .box-detail {
    top: 85%;
    display: grid;
  }
  .icon {
    display: grid;
    place-items: center;
    margin-left: 0;
  }
  .icon-detail {
    display: grid;
    place-items: center;
    margin-top: 10px;
    width: 40%;
  }
  .desk-icon {
    text-align: center;
  }
  .box-detail {
    height: auto;
  }
  .welcome {
    padding-top: 300px;
  }
  .title-bimbel {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title-bimbelsekolah {
    padding: 20px;
  }
  .title-bimbel {
    padding: 20px;
    padding-bottom: 0;
  }
  .box-pengajar {
    padding: 10px;
  }
  .desk-pengajar {
    text-align: justify;
  }
  .img-landing2 {
    width: 70%;
  }
  .img-teach {
    width: 100%;
  }
  .desk-story {
    margin-left: 0;
  }
  .list-story {
    column-count: 1;
  }
  .li-fasilitas {
    margin-top: 20px;
  }
  .desk-quot {
    font-size: 15px;
    text-align: justify;
  }
  .end-qout {
    padding: 20px;
  }
}

/* SAMSUNG FOLD */
@media only screen and (max-width: 280px) {
  .content-desk {
    padding: 30px;
  }
  .title-utama {
    font-size: 30px;
  }
  .deks-landing {
    width: 100%;
    text-align: justify;
    font-size: 18px;
  }
  .img-landing {
    margin-top: 0;
  }
  .box-detail {
    top: 85%;
    display: grid;
  }
  .icon {
    display: grid;
    place-items: center;
    margin-left: 0;
  }
  .icon-detail {
    display: grid;
    place-items: center;
    margin-top: 10px;
    width: 40%;
  }
  .desk-icon {
    text-align: center;
  }
  .box-detail {
    height: auto;
  }
  .welcome {
    padding-top: 300px;
  }
  .title-bimbel {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .title-bimbelsekolah {
    padding: 20px;
  }
  .title-bimbel {
    padding: 20px;
    padding-bottom: 0;
  }
  .box-pengajar {
    padding: 10px;
  }
  .desk-pengajar {
    text-align: justify;
  }
  .img-landing2 {
    width: 70%;
  }
  .img-teach {
    width: 100%;
  }
  .desk-story {
    margin-left: 0;
  }
  .list-story {
    column-count: 1;
  }
  .li-fasilitas {
    margin-top: 20px;
  }
  .desk-quot {
    font-size: 15px;
    text-align: justify;
  }
  .end-qout {
    padding: 20px;
  }
  .card-bimbelkhusus {
    display: grid;
    width: 100%;
  }
  .keunggulan-edu {
    width: 100%;
  }
  .main-bimkhus {
    margin-left: 40px;
  }
  .title-bimkhus {
    margin-left: 40px;
  }
  .desk-bimkhus {
    margin-left: 40px;
  }
  .main-bimskol {
    margin-left: 40px;
  }
  .slider-testi {
    width: 100%;
  }
  .desk-bimskol {
    margin-left: 40px;
  }
  .title-bimbelsekolah {
    margin-left: 40px;
  }
}